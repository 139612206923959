import Loading from '@copets/components/Loading'
import NotFoundPage from '@copets/pages/NotFoundPage'

import {
  CHANGE_PASSWORD,
  CONVERSATION,
  CONVERSATIONS,
  EDIT_PROFILE,
  LOGIN,
  ONBOARDING,
  PROFILE,
  REQUEST_PASSWORD_RESET,
  SEARCH,
  SIGN_UP,
} from '.'

const routes = [
  {
    path: '/',
    lazy: () => import('../Providers'),
    errorElement: <NotFoundPage />,
    HydrateFallback: Loading,
    children: [
      {
        path: '/',
        lazy: () => import('../layouts/AppLayout'),
        children: [
          {
            path: ONBOARDING,
            lazy: () => import('../pages/OnboardingPage'),
          },
          {
            path: '/',
            lazy: () => import('./OnboardingRoute'),
            children: [
              {
                path: SEARCH,
                lazy: () => import('../pages/SearchPage'),
              },
              {
                path: PROFILE(),
                lazy: () => import('../pages/profile/ProfilePage'),
              },
              {
                path: CONVERSATIONS,
                lazy: () => import('./AuthProtectedRoute'),
                children: [
                  {
                    path: CONVERSATIONS,
                    lazy: () => import('../pages/conversations/ConversationsPage'),
                  },
                  {
                    path: CONVERSATION(),
                    lazy: () => import('../pages/conversations/ConversationPage'),
                  },
                ],
              },
              {
                path: '/user',
                lazy: () => import('./AuthProtectedRoute'),
                children: [
                  {
                    path: EDIT_PROFILE,
                    lazy: () => import('../pages/user/EditProfilePage'),
                  },
                  {
                    path: CHANGE_PASSWORD,
                    lazy: () => import('../pages/user/ChangePasswordPage'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/auth',
        lazy: () => import('./PublicProtectedRoute'),
        children: [
          {
            path: '/auth',
            lazy: () => import('../layouts/AuthLayout'),
            children: [
              {
                path: LOGIN,
                lazy: () => import('../pages/auth/LoginPage'),
              },
              {
                path: SIGN_UP,
                lazy: () => import('../pages/auth/SignUpPage'),
              },
              {
                path: REQUEST_PASSWORD_RESET,
                lazy: () => import('../pages/auth/RequestPasswordResetPage'),
              },
            ],
          },
        ],
      },
    ],
  },
]

export { routes }
