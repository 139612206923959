import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { createBrowserRouter as RRDcreateBrowserRouter } from 'react-router-dom'

import { defaultLocale, dynamicActivate } from './i18n'
import { routes } from './router/routes'

const createBrowserRouter = import.meta.env.PROD
  ? Sentry.wrapCreateBrowserRouter(RRDcreateBrowserRouter)
  : RRDcreateBrowserRouter

const router = createBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
})

const App = () => {
  useEffect(() => {
    dynamicActivate(defaultLocale)
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </I18nProvider>
  )
}
export default App
