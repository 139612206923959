export const SEARCH = '/'
export const PROFILE = (userId?: string) => `/profile/${userId ? userId : ':userId'}`
export const CONVERSATIONS = '/conversations'
export const CONVERSATION = (conversationId?: string) =>
  `${CONVERSATIONS}/${conversationId ? conversationId : ':conversationId'}`
export const EDIT_PROFILE = '/user/edit-profile'
export const CHANGE_PASSWORD = '/user/change-password'
export const LOGIN = '/auth/login'
export const SIGN_UP = '/auth/sign-up'
export const REQUEST_PASSWORD_RESET = '/auth/reset-password'
export const ONBOARDING = '/onboarding'
